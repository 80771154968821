
import { defineComponent, ref, onMounted, computed, watch } from "vue";
import { Form, Field, ErrorMessage, useForm } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useI18n } from "vue-i18n";
import KTLoader from "@/components/Loader.vue";
import { loaderLogo, loaderEnabled } from "@/core/helpers/config";
import { ElLoading } from "element-plus";
import HtmlClass from "@/core/services/LayoutService";

export default defineComponent({
  name: "SignIn",
  components: {
    Form,
    Field,
    ErrorMessage,
    KTLoader,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const email = ref("");
    const password = ref("");
    const submitButton = ref<HTMLElement | null>(null);
    const { handleSubmit } = useForm();
    const isNameRequired = (value) => {
      if (!value) {
        return t("ruser");
      }
      return true;
    };

    const changeLoading = computed(() => store.getters.LoadingStatus);
    const loaderShow = computed(() => store.getters.isLoading);
    HtmlClass.init();
    const loading = ref(null as any);
    watch(
      () => changeLoading.value,
      (first) => {
        if (changeLoading.value) {
          loading.value = ElLoading.service({
            lock: true,
            text: t("iloading"),
            background: "rgba(0, 0, 0, 0.7)",
          });
        } else if (loading.value !== null) {
          loading.value.close();
        }
      },
      { deep: true }
    );
    const isPasswordRequired = (value) => {
      if (!value) {
        return t("rpasswordrequired");
      }
      if (value.length < 4) {
        return t("rpassword");
      }
      return true;
    };

    const onSubmit = (value) => {
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      store.commit("setLoadingStatus", true);
      store
        .dispatch(Actions.LOGIN, value)
        .then(() => {
          store.dispatch("myPermissions");
          store.dispatch(Actions.MY_PROFILE).then(() => {
            router.push({ name: "dashboard" });
            submitButton.value?.removeAttribute("data-kt-indicator");
            store.commit("setLoadingStatus", false);
          });
        })
        .catch(() => {
          submitButton.value?.removeAttribute("data-kt-indicator");
          store.commit("setLoadingStatus", false);
        });
    };

    onMounted(() => {
      if (store.getters.isUserAuthenticated) {
        router.push({ name: "dashboard" });
      }
    });

    return {
      loaderEnabled,
      loaderShow,
      loaderLogo,
      email,
      password,
      submitButton,
      onSubmit,
      isNameRequired,
      isPasswordRequired,
    };
  },
});
